<template>
  <ul class="d-flex flex-column flex-sm-row steps p-0">
    <li
      class="d-flex align-items-center justify-content-center mb-2 mb-sm-0"
      v-for="(step, i) in steps"
      :key="i"
      @click="setActiveStep(i + 1)"
      :class="[(i+1) < activeStep && 'cursor-pointer']"
    >
      <div
        class="steps__id d-flex align-items-center justify-content-center rounded-circle fs-12"
        :class="[
          activeStep === i + 1
            ? 'text-white bg-primary'
            : 'text-primary bg-gray6',
          activeStep > i + 1 && 'border border-primary',
        ]"
      >
        <span v-if="i + 1 > activeStep || i + 1 === activeStep">{{
          step.id
        }}</span>
        <Tick v-else :width="12" :height="12" fill="#005C17" />
      </div>
      <p class="mb-0 mr-3 ml-2 text-primary">{{ $t(step.text) }}</p>
      <RightChevron class="mr-3" v-if="i < steps.length - 1" />
    </li>
  </ul>
</template>

<script>
import RightChevron from "@/components/svg/RightChevron.vue";
import Tick from "@/components/svg/Tick.vue";
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    activeStep: {
      type: Number,
      default: 1,
    },
  },
  components: { RightChevron, Tick },
  methods: {
    setActiveStep(step) {
      if (this.activeStep > step) {
        this.$emit("stepChanged", step);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.steps {
  &__id {
    width: 28px;
    height: 28px;
  }
}
</style>
