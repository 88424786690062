<template>
  <div class="current-address">
    <p
      class="text-danger fw-bold text-capitalize"
      v-if="getErrors.address && getErrors.address.length"
    >
      {{ getErrors.address }}
    </p>
    <ValidationObserver
      ref="currentAddress"
      v-slot="{ handleSubmit }"
      slim
      name="currentAddress"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div
          class="row align-items-end flex-wrap flex-lg-nowrap justify-content-start justify-content-sm-between"
        >
          <div
            :class="[classes]"
            v-for="{
              classes,
              name,
              label,
              component,

              options = [],

              ...attrs
            } in addressDetails"
            :key="name"
          >
            <component
              :is="component"
              :name="name"
              :label="label"
              v-bind="attrs"
              :validate="false"
              v-model="formData[name]"
            >
              <template v-if="options.length">
                <option
                  v-for="({ value, text, ...rest }, i) in options"
                  v-bind="rest"
                  :key="i"
                  :value="value"
                >
                  {{ text }}
                </option>
              </template>
            </component>
          </div>

          <div class="col-sm-6 col-lg-4 mb-3 py-0">
            <ButtonWithIcon
              class="text-blackbtn-white"
              :text="$t('currentAddress.searchAddress')"
              type="submit"
              v-if="searchable"
            >
              <Search v-if="!getLoadings.address" />
              <b-spinner v-else small label="Spinning" variant="primary"></b-spinner>
            </ButtonWithIcon>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
    <div class="row mt-2">
      <div
        :class="classes"
        v-for="{ classes, name, label, component, ...attrs } in disabledAddressFields"
        :key="name"
      >
        <component
          :is="component"
          :name="name"
          :label="label"
          v-bind="attrs"
          v-model="addressFormData[name]"
          :disabled="true"
          :validate="false"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/ui/form/controls/TextInput.vue";
import { ValidationObserver } from "vee-validate";
import ButtonWithIcon from "../../ButtonWithIcon.vue";
import Search from "../../../svg/Search.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    submit: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      default() {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    partialName: {
      type: String,
      default: "address",
    },
  },
  components: {
    ValidationObserver,
    TextInput,
    ButtonWithIcon,
    Search,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    addressDetails() {
      const details = [
        {
          floatingLabel: false,
          name: "zipcode",
          component: "TextInput",
          value: "",
          rules: this.searchable ? "required" : "",
          placeholder: this.$t("currentAddress.zipcode"),
          // placeholder: "Enter zipcode",
          label: `${this.$t("currentAddress.zipcode")}*`,
          classes: "col-12 col-sm-6 col-lg-4 py-0",
          disabled: this.searchable ? false : true,
        },
        {
          floatingLabel: false,
          name: "houseNr",
          component: "TextInput",
          value: "",
          rules: "required",
          placeholder: this.$t("currentAddress.nr"),
          label: `${this.$t("housenumber")}*`,
          classes: `col-12 col-sm-3 col-lg-2 py-0`,
        },
        {
          floatingLabel: false,
          name: "houseAd",
          component: "TextInput",
          value: "",
          // placeholder: this.$t("currentAddress.ad"),
          label: this.$t("addition"),
          placeholder: "Add.",
          classes: "col-12 col-sm-3 col-lg-2 py-0",
        },
      ];
      return this.searchable ? details : details.slice(0, 1);
    },
  },
  data() {
    return {
      addressFormData: {
        streetName: "",
        // houseNr: "",
        // houseAd: "",
        city: "",
        municipality: "",
        address: "",
      },
      formData: {
        zipcode: "",
        houseNr: null,
        houseAd: "",
      },
      disabledAddressFields: [
        {
          floatingLabel: false,
          name: "address",
          component: "TextInput",
          placeholder: this.$t("Address"),
          label: this.$t("Adress"),
          classes: "col-12 py-0",
          value: "",
        },
      ],
    };
  },
  methods: {
    ...mapActions("user", ["getAddress"]),
    async onSubmit() {
      this.addressFormData = await this.getAddressData();
      this.$emit("partialData", {
        formData: this.addressFormData,
        partialName: this.partialName || "address",
      });
    },
    async getAddressData() {
      const payload = {
        zipcode: this.formData.zipcode,
        house_number: this.formData.houseNr,
        house_ad: this.formData.houseAd,
      };
      const location = await this.getAddress(payload);
      let address = `${location.street} ${location.number} ${this.formData.houseAd} ${location.city}`;
      console.log("address", address);
      return {
        streetName: location.street,
        city: location.city,
        houseNr: location.number,
        municipality: location.municipality,
        houseAd: this.formData.houseAd,
        zipcode: this.formData.zipcode,
        energyLabel: location.energy_label,
        yearOfConstruction: location.yearOfConstruction,
        address: address,
      };
    },
    setInitialValue(initValue) {
      if (Object.keys(initValue).length) {
        for (let key in this.formData) {
          if (initValue[key]) {
            this.formData[key] = initValue[key];
          }
        }
        for (let key in this.addressFormData) {
          if (initValue[key]) {
            this.addressFormData[key] = initValue[key];
          }
        }
      }
    },
  },
  created() {
    this.setInitialValue(this.initialValues);
  },
  watch: {
    submit(newVal) {
      if (newVal) {
        this.$emit("partialData", {
          formData: this.addressFormData,
          partialName: this.partialName,
        });
      }
    },
    initialValues(newValue) {
      this.setInitialValue(newValue);
    },
  },
};
</script>

<style lang="scss">
.address {
  &__zip {
    max-width: 135px;

    @include xs {
      max-width: 100%;
      width: 100%;
    }
  }

  &__sm {
    width: 50px;

    @include xs {
      width: 50%;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background: none;
    }

    input {
      padding: 0.8rem 0.5625rem;
    }
  }
}
</style>
