<template>
  <div class="broker__addlistings__inputData notranslate">
    <file-pond
      name="photo"
      ref="pond"
      :label-idle="heading"
      :allow-multiple="multiple"
      :allow-reorder="true"
      accepted-file-types="image/jpeg, image/png"
      :server="server"
      v-bind:files="files"
      @init="handleFilePondInit"
      @reorderfiles="emitData"
      @processfiles="fileuploaded"
    />
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import { mapActions } from "vuex";
export default {
  props: {
    heading: {
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilePond,
  },
  data() {
    return {
      files: [],
      server: {
        // url: "http://8c34-182-182-117-196.ngrok.io/media/listing",
        // headers: {
        //   Authorization:
        // },
        process: this.processMedia,
        // url: "https://api.eerlijktehuur.nl/",
        load: (source, load) => {
          source = `${source}?t=` + Date.now();
          const myRequest = new Request(source);
          fetch(myRequest).then(function (response) {
            response.blob().then(function (myBlob) {
              load(myBlob);
            });
          });
        },
        remove: this.remove,
      },
      photos: [],
      // currentMediaType: "",
      // media: {
      //   photos: [],
      //   floorplan: [],
      // },
    };
  },
  mounted() {
    this.files = JSON.parse(JSON.stringify(this.value));
  },
  methods: {
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
      // FilePond instance methods are available on `this.$refs.pond`
    },

    async remove(source, load) {
      const deletedFile = this.$refs.pond.getFiles().find((file) => {
        return file.source === source;
      });
      const deletedFileId = deletedFile.getMetadata("id");
      const deletedFileListingId = deletedFile.getMetadata("listingId");
      await this.deleteListingMedia({
        id: deletedFileId,
        listingId: deletedFileListingId,
      });
      // console.log(this.$refs.pond.getFile(source));
      load();
      console.log("deletedFile", deletedFile, deletedFileId, this.value);
      let index = this.value.findIndex((x) => x.id == deletedFileId);
      let files = this.value;
      files.splice(index, 1);
      this.$emit("input", files);
    },

    async processMedia(fieldName, file, metadata, load) {
      const res = await this.uploadMedia({ body: { photo: file } });
      let files = this.value;
      files.push({
        id: res.photo.id,
        source: res.photo.path,
        options: {
          type: "local",
          metadata: {
            id: res.photo.id,
            filename: res.photo.filename,
            listingId: res.photo.ListingId,
          },
        },
      });

      console.log("res", res, files);
      // files.push(res.photo);
      this.$emit("input", files);
      load();
    },
    ...mapActions("listing", [
      "uploadMedia",
      "deleteListingMedia",
      // "swapMediaOrder",
    ]),
    emitData($event, fromIndex, toIndex) {
      console.log("emitData");
      let photos = this.value;
      [photos[fromIndex], photos[toIndex]] = [photos[toIndex], photos[fromIndex]];
      this.$emit("input", photos);
    },
    fileuploaded($event) {
      console.log("uploaded", $event);
    },
  },
  watch: {
    submit(newVal) {
      if (newVal) {
        this.emitData();
      }
    },
  },
  created() {},
};
</script>

<style lang="scss">
// .filepond--root {
//   min-height: 15.4375rem;
// }
.filepond--panel-root {
  background-color: #e8e8e8;
}

.filepond--item {
  width: calc(33.33% - 0.5em);
}

.filepond--drop-label {
  color: #afafaf;
  font-weight: bold;
  font-size: 1.125rem !important;
}

// .broker__addlistings {
//   &__media {
//     margin-bottom: 22px;
//     &__container {
//       max-width: 550px;
//       margin-right: 10px;
//     }
//     &__filenamesContainer {
//       background: $gray2;
//       &__text {
//         @include font(1rem, #504c4c, 400);
//       }
//     }
//     &__featured {
//       transform: translateY(7px);
//     }
//   }
// }
</style>
