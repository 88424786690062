<template>
  <div class="broker__addlistings">
    <div class="d-flex flex-column align-items-center">
      <SectionHeading :heading="formHeading" />
      <Steps
        @stepChanged="(newStep) => (activeStep = newStep)"
        :steps="steps"
        :activeStep="activeStep"
      />
      <BRow class="w-100 mb-4" v-if="activeStep > 1">
        <BCol class="mx-auto px-0" cols="12">
          <CardV2 class="d-flex flex-column flex-sm-row w-100">
            <div
              class="bg-primary broker__addlistings__address rounded-circle d-flex justify-content-center align-items-center"
            >
              <House />
            </div>
            <div class="ml-0 ml-sm-3 mt-3 mt-sm-0" v-if="listing">
              <h5 class="fs-18 fw-bold">
                {{ listing.streetName }} {{ listing.houseNr }}
                {{ listing.houseAd }}
              </h5>
              <p class="text-darkblue fs-14">
                {{ listing.municipality }}, {{ listing.zipcode }},
                {{ listing.city }}
              </p>
            </div>
          </CardV2>
        </BCol>
      </BRow>
    </div>
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit, errors }"
      slim
      v-if="!getLoadings.set_listing"
    >
      <p class="text-danger fw-bold text-capitalize" v-if="getErrors.addUpdateListing">
        {{ getErrors.addUpdateListing }}
      </p>
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <BRow>
          <BCol class="mx-auto" cols="12">
            <CardV2
              v-if="activeStep === 1"
              cardClass="broker__addlistings__inputData__card mb-4"
              heading="Property Address"
            >
              <div class="broker__addlistings__inputData__card__address">
                <CurrentAddress
                  :submit="submitted"
                  @partialData="onPartialData"
                  :initialValues="addressInitialValues"
                />
              </div>
            </CardV2>

            <template v-else-if="activeStep === 2">
              <CardV2
                cardClass="broker__addlistings__inputData__card"
                class="mb-4"
                v-for="inputData in formInfo"
                :key="inputData.partialName"
              >
                <CardHeading :heading="inputData.heading" externalClass="mb-4" />
                <div class="broker__addlistings__inputData__container">
                  <FormPartial
                    @input-with-name="inputChange"
                    :partialName="inputData.partialName"
                    :submit="submitted"
                    :formFields="inputData.inputs"
                    @partialData="onPartialData"
                    :resetForm="resetForm"
                    :edit="edit"
                  />
                </div>
              </CardV2>
              <h5 class="fw-bold card-v2__heading mb-4">
                {{ $t("Media") }}
              </h5>
              <label>{{ $t("Feature image") }}*</label>
              <Media
                :heading="$t('Drag and drop feature image')"
                partialName="featureImage"
                :submit="submitted"
                :multiple="false"
                :areRequired="requiredFields"
                v-model="formData.feature_images"
              />
              <label>{{ $t("Additional images") }}*</label>
              <Media
                :heading="$t('Drag and drop images')"
                :multiple="true"
                @filesUpdated="formData.photos = $event"
                :areRequired="requiredFields"
                v-model="formData.images"
              />
            </template>

            <template v-else-if="activeStep === 3">
              <Step3 :submitted="submitted" @partialData="onPartialData" />
            </template>
          </BCol>
          <!-- form fields -->

          <BCol cols="12" class="mb-4 mb-lg-0 mx-auto mt-3 broker__addlistings__btns">
            <!-- submit button data -->
            <div
              class="d-flex align-items-center justify-content-end flex-column flex-sm-row"
            >
              <button
                type="button"
                v-if="activeStep > 1"
                class="btn btn-white"
                @click="goToPrevStep"
              >
                {{ $t("previous") }}
              </button>
              <button
                class="btn btn-gray d-flex align-items-center justify-content-center justify-content-lg-start ml-3 mt-2 mt-sm-0"
                v-if="activeStep === 3"
                :disabled="getLoadings.addUpdateListing"
                @mouseenter="onMouseEnter(false)"
                @mouseleave="onMouseLeave"
                @click="submitType = 'draft'"
              >
                {{ addListingLocale.sidePanel.draft }}
                <b-spinner
                  v-if="getLoadings.addUpdateListing && submitType === 'draft'"
                  small
                  label="Spinning"
                  variant="primary"
                ></b-spinner>
              </button>

              <button
                :disabled="getLoadings.addUpdateListing"
                class="btn btn-primary text-center ml-sm-3 mt-2 mt-sm-0"
                type="submit"
                @mouseenter="onMouseEnter(true)"
                @mouseleave="onMouseLeave"
                @click="
                  submitType = 'publish';
                  submit = true;
                "
              >
                {{ activeStep === 3 ? addListingLocale.sidePanel.publish : $t("next") }}
                <b-spinner
                  v-if="getLoadings.addUpdateListing && submitType === 'publish'"
                  small
                  label="Spinning"
                  variant="white"
                ></b-spinner>
              </button>
            </div>
            <div class="alert alert-danger mt-4 errors">
              <ul class="mb-0">
                <template v-for="(error, key) in errors">
                  <li class="text-danger error" v-if="error.length" :key="key">
                    {{ key }} has missing or invalid data
                  </li>
                </template>
                <li
                  class="text-danger error"
                  v-if="
                    (formData.feature_images.length == 0 ||
                      formData.images.length == 0) &&
                    activeStep == 2 &&
                    submit
                  "
                >
                  Images are missing
                </li>
              </ul>
            </div>
          </BCol>
        </BRow>
      </b-form>
    </ValidationObserver>
    <Spinner v-else />
  </div>
</template>

<script>
import SectionHeading from "@/components/general/SectionHeading.vue";
import { ValidationObserver } from "vee-validate";
import Media from "@/components/Broker/Listings/add/Media.vue";

import CardHeading from "@/components/general/CardHeading.vue";

import CurrentAddress from "@/components/ui/form/profile/CurrentAddress.vue";
import FormPartial from "@/components/ui/form/FormPartial.vue";
import { mapGetters, mapActions } from "vuex";
import Spinner from "../../../components/ui/Spinner.vue";
import toastMixin from "@/mixins/toastMixin";
import CardV2 from "../../../components/general/CardV2.vue";
import House from "../../../components/svg/House.vue";
import Steps from "../../../components/Broker/Listings/add/Steps.vue";
import Step3 from "./Step3.vue";

export default {
  mixins: [toastMixin],
  components: {
    SectionHeading,
    ValidationObserver,
    Media,
    CardHeading,
    // RichTextEditor,
    CurrentAddress,
    FormPartial,
    Spinner,
    CardV2,
    Steps,
    House,
    Step3,
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          text: this.$t("Property Address"),
        },
        {
          id: 2,
          text: this.$t("Listing Information"),
        },
        {
          id: 3,
          text: this.$t("Preferences"),
        },
      ],
      activeStep: 1,
      status: "Draft",
      submitType: "publish",
      resetForm: false,
      hidePlotAreaField: false,
      hideServiceCostField: true,
      hideUtilityCostField: true,
      hidePreferredMinIncomeField: false,
      hideRentalPeriod: true,
      hideYearlyRentIncreasePercentage: false,
      requiredFields: true,
      submitted: false,
      submit: false,
      formData: {
        income_type: null,
        streetName: null,
        houseNr: null,
        houseAd: null,
        municipality: null,
        city: null,
        price: null,
        exServiceCost: null,
        exUtilityCost: null,
        serviceCost: null,
        utilityCost: null,
        deposit: null,
        availablityDate: null,
        interior: null,
        propertyType: null,
        totalRooms: null,
        totalBedrooms: null,
        livingArea: null,
        plotArea: null,
        volumeInCBM: null,
        yearOfConstruction: null,
        outsideSpace: [],
        energyLabel: null,
        parkingFacilities: [],
        storage: [],
        preferredHousehold: [],
        preferredAnimals: null,
        preferred_max_income: null,
        preferredMinIncome: null,
        photos: [],
        feature_image: null,
        feature_images: [],
        images: [],
        floorplan: [],
        description: null,
        zipcode: null,
        preferredViewingDates: [],
        landlord: null,
      },

      // description: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [],
      ],
      arrFields: ["outsideSpace", "parkingFacilities", "storage", "preferredHousehold"],
      edit: false,
      disabledEnergyLabel: false,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("listing", ["listing"]),
    initalPreferredDate() {
      return this.listing && this.listing.listing
        ? this.listing.preferredViewingDates
        : [];
    },

    formInfo() {
      const listing = this.listing;

      return [
        {
          partialName: "transferinformation",
          heading: this.addListingLocale.transferInfo.heading,

          inputs: [
            {
              mode: "passive",
              name: "price",
              component: "TextInput",
              rules: this.requiredFields ? "required" : "",
              value: listing && listing.price ? listing.price.toString() : "",
              placeholder: `${this.addListingLocale.transferInfo.monthlyRent}*`,
              label: `${this.addListingLocale.transferInfo.monthlyRent}*`,
              classes: "col-sm-6 col-12",
              masked: true,
              floatingLabel: false,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "€",
            },
            {
              mode: "passive",
              name: "deposit",
              component: "TextInput",
              value:
                listing && listing.deposit ? listing.deposit.toLocaleString("nl-NL") : "",
              rules: "",
              label: this.$t("Deposit"),
              placeholder: this.$t("Deposit (optional)"),
              classes: "col-12 col-sm-6",
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",

                      // additional number interval options (e.g.)
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "€",
              floatingLabel: false,
            },
            {
              mode: "passive",
              name: "exServiceCost",
              component: "RadioInput",
              emitWithName: true,
              value:
                listing && listing.exServiceCost != null ? listing.exServiceCost : true,
              rules: "required",
              label: this.$t("Extra service costs included?"),
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: true,
                  text: "Yes",
                },
                {
                  value: false,
                  text: "No",
                },
              ],
              defaultValue: true,
            },
            {
              mode: "passive",
              name: "exUtilityCost",
              component: "RadioInput",
              emitWithName: true,
              value: listing ? listing.exUtilityCost : null,
              label: this.$t("Ex. utility costs"),
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: null,
                  text: this.$t("Does not apply"),
                },
                {
                  value: true,
                  text: "Yes",
                },
                {
                  value: false,
                  text: "No",
                },
              ],
              defaultValue: true,
            },
            {
              floatingLabel: false,
              mode: "passive",
              name: "serviceCost",
              component: "TextInput",
              value:
                listing && listing.serviceCost
                  ? listing.serviceCost.toLocaleString("nl-NL")
                  : "",
              placeholder: this.$t("Service costs"),
              label: this.$t("Service costs"),
              classes: "col-sm-6 col-12",
              rules: this.requiredFields ? "required_if:exServiceCost,true" : "",
              hide: this.hideServiceCostField,
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number,
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "€",
            },
            {
              floatingLabel: false,
              mode: "passive",
              name: "utilityCost",
              component: "TextInput",
              hide: !this.hideUtilityCostField,
              value:
                listing && listing.utilityCost
                  ? listing.utilityCost.toLocaleString("nl-NL")
                  : "",
              placeholder: this.$t("Estimated utility costs"),
              label: this.$t("Estimated utility costs"),
              classes: "col-sm-6 col-12",
              rules: "required_if:exUtilityCost,false",
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",

                      // additional number interval options (e.g.)
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "€",
            },

            {
              mode: "passive",
              name: "availablityDate",
              component: "Datepicker",
              value: (listing && listing.availablityDate) || "",
              rules: this.requiredFields ? "required" : "",
              placeholder: this.addListingLocale.transferInfo.availDate,
              label: this.addListingLocale.transferInfo.availDate,
              classes: "col-sm-6 col-12",
              defaultValue: "",
            },

            {
              mode: "passive",
              name: "interior",
              component: "SelectInput",
              rules: this.requiredFields ? "required" : "",
              value: (listing && listing.interior) || "",
              placeholder: this.addListingLocale.transferInfo.interior[0],
              label: `${this.addListingLocale.transferInfo.interior[0]}*`,
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: "",
                  text: this.$t("Select option"),
                  disabled: true,
                  hidden: true,
                },
                {
                  value: "Clean",
                  text: this.$t("Clean"),
                },
                {
                  value: "Furnished",
                  text: this.$t("Furnished"),
                },
                {
                  value: "Unfurnished",
                  text: this.$t("Unfurnished"),
                },
              ],
              defaultValue: "",
            },

            {
              mode: "passive",
              name: "rental_period_type",
              component: "RadioInput",
              emitWithName: true,
              value:
                listing && listing.rental_period_type
                  ? listing.rental_period_type
                  : "undetermined",
              rules: "required",
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: "temporary",
                  text: this.$t("Temporary"),
                },
                {
                  value: "undetermined",
                  text: this.$t("Undetermined"),
                },
              ],
              defaultValue: "undetermined",
              label: this.$t("rental_period"),
            },

            {
              mode: "passive",
              name: "is_yearly_increament",
              component: "RadioInput",
              emitWithName: true,
              value:
                listing && listing.is_yearly_increament
                  ? listing.is_yearly_increament
                  : false,
              rules: "required",
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: true,
                  text: this.$t("yes"),
                },
                {
                  value: false,
                  text: this.$t("no"),
                },
              ],
              defaultValue: false,
              label: this.$t("yearly_rent_increases"),
            },

            {
              floatingLabel: false,
              mode: "passive",
              name: "rental_period",
              component: "TextInput",
              value: listing && listing.rental_period ? `${listing.rental_period}` : "",
              classes: "col-sm-6 col-12 pt-0",
              rules: this.requiredFields
                ? "required_if:rental_period_type,temporary"
                : "",
              placeholder: this.$t("amount_of_months"),
              hide: this.hideRentalPeriod,
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: this.$t("Months"),
            },

            {
              floatingLabel: false,
              mode: "passive",
              name: "rent_increase_percentage",
              component: "TextInput",
              value:
                listing && listing.rent_increase_percentage
                  ? `${listing.rent_increase_percentage}`
                  : "",
              classes: "col-sm-6 col-12 pt-0",
              placeholder: this.$t("Percentage per year (optional)"),
              hide: this.hideYearlyRentIncreasePercentage,
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      min: 0,
                      max: 100,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "%",
            },

            {
              floatingLabel: false,
              mode: "passive",
              name: "recent_woz_value",
              component: "TextInput",
              value:
                listing && listing.recent_woz_value
                  ? listing.recent_woz_value.toString()
                  : "",
              placeholder: this.$t("most_recent_woz_value"),
              label: this.$t("most_recent_woz_value"),
              classes: "col-sm-6 col-12",
              masked: true,
              mask: [
                {
                  mask: "num",
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",

                      // additional number interval options (e.g.)
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              prepend: "€",
            },

            {
              mode: "passive",
              name: "liberalization",
              component: "RadioInput",
              emitWithName: true,
              value: listing && listing.liberalization ? `${listing.liberalization}` : "",
              rules: "required",
              classes: "col-sm-6 col-12",
              options: [
                {
                  value: "does_not_apply",
                  text: this.$t("Does not apply"),
                },
                {
                  value: "social_rent",
                  text: this.$t("Social rent"),
                },
                {
                  value: "free_sector",
                  text: this.$t("Free sector"),
                },
              ],
              defaultValue: false,
              label: this.$t("Liberalization"),
            },
          ],
        },

        {
          partialName: "Propertydetails",
          heading: this.addListingLocale.propertyDetails.heading,
          inputs: [
            {
              mode: "passive",
              name: "propertyType",
              component: "selectInput",
              value: (listing && listing.propertyType) || "",
              rules: this.requiredFields ? "required" : "",
              placeholder: this.addListingLocale.propertyDetails.type[0],
              classes: "col-6",
              emitWithName: true,
              options: [
                {
                  text: "Select Type",
                  value: "",
                },
                {
                  text: "Woning",
                  value: "House",
                },
                {
                  text: "Appartement",
                  value: "Appartment",
                },
                {
                  text: "Studio",
                  value: "Studio",
                },
                {
                  text: "Kamer",
                  value: "Room",
                },
              ],
              defaultValue: "",
              label: `${this.$t("propertyType")}*`,
            },

            {
              floatingLabel: false,
              mode: "passive",
              rules: "numeric",
              name: "floor",
              component: "TextInput",
              value: listing && listing.floor ? listing.floor.toString() : "",
              placeholder:
                this.formData["propertyType"] == "House"
                  ? this.$t("number_of_floors")
                  : this.$t("floor_level"),
              label:
                this.formData["propertyType"] == "House"
                  ? this.$t("number_of_floors")
                  : this.$t("floor_level"),
              classes: "col-sm-6 col-12",
              defaultValue: "",
            },

            {
              mode: "passive",
              name: "totalRooms",
              component: "TextInput",
              value: (listing && listing.totalRooms) || null,
              rules: `${this.requiredFields ? "required" : ""}|numeric`,
              placeholder: this.addListingLocale.propertyDetails.totalRooms,
              classes: "col-sm-4 col-12",
              defaultValue: null,
              label: `${this.$t("rooms")}*`,
              floatingLabel: false,
            },
            {
              mode: "passive",
              name: "totalBedrooms",
              component: "TextInput",
              value: (listing && listing.totalBedrooms) || null,
              rules: `${this.requiredFields ? "required" : ""}|numeric`,
              placeholder: this.addListingLocale.propertyDetails.totalBedrooms,
              classes: "col-sm-4 col-12",
              defaultValue: null,
              label: `${this.$t("bedRooms")}*`,
              floatingLabel: false,
            },
            {
              mode: "passive",
              name: "livingArea",
              component: "TextInput",
              value: (listing && listing.livingArea) || "",
              rules: `${this.requiredFields ? "required" : ""}`,
              placeholder: this.$t("livingArea"),
              classes: `col-sm-4 col-12`,
              masked: true,
              mask: [
                {
                  mask: "",
                },
                {
                  mask: "num m²",
                  placeholderChar: "Min. living space",
                  lazy: false,
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              floatingLabel: false,
              label: this.addListingLocale.propertyDetails.livingArea,
            },
            // {
            //   mode: "passive",
            //   name: "plotArea",
            //   component: "TextInput",
            //   value:
            //     (listing && listing.plotArea) || "",
            //   rules: `${this.requiredFields ? "required" : ""}`,
            //   placeholder: this.addListingLocale.propertyDetails.plotArea,
            //   classes: "col-sm-4 col-12",
            //   masked: true,
            //   hide: this.hidePlotAreaField,
            //   mask: [
            //     {
            //       mask: "",
            //     },
            //     {
            //       mask: "num m²",
            //       placeholderChar: "Min. living space",
            //       lazy: false,
            //       blocks: {
            //         num: {
            //           mask: Number, // enable number mask
            //           scale: 2,
            //           signed: false,
            //           thousandsSeparator: ".",
            //           padFractionalZeros: false,
            //           normalizeZeros: true,
            //           radix: ",",
            //           min: 0,
            //           max: 99999999,
            //         },
            //       },
            //     },
            //   ],
            //   defaultValue: "",
            // },
            {
              mode: "passive",
              name: "volumeInCBM",
              component: "TextInput",
              value: (listing && listing.volumeInCBM) || "",
              placeholder: this.$t("Volume in m3"),
              classes: "col-sm-4 col-12",
              masked: true,
              mask: [
                {
                  mask: "",
                },
                {
                  mask: "num m³",
                  placeholderChar: "Min. living space",
                  lazy: false,
                  blocks: {
                    num: {
                      mask: Number, // enable number mask
                      scale: 2,
                      signed: false,
                      thousandsSeparator: ".",
                      padFractionalZeros: false,
                      normalizeZeros: true,
                      radix: ",",
                      min: 0,
                      max: 99999999,
                    },
                  },
                },
              ],
              defaultValue: "",
              floatingLabel: false,
              label: this.$t("Volume in m2"),
            },
            {
              mode: "passive",
              name: "yearOfConstruction",
              component: "TextInput",
              value: (listing && listing.yearOfConstruction) || null,
              rules: `${this.requiredFields ? "required" : ""}|numeric`,
              placeholder: this.addListingLocale.propertyDetails.yearOfConstruction,
              classes: "col-sm-4 col-12",
              defaultValue: null,
              floatingLabel: false,
              label: `${this.$t("Year of construction")}*`,
            },
            {
              mode: "passive",
              name: "energyLabel",
              component: "SelectInput",
              value: listing?.energyLabel
                ? listing.energyLabel.toLowerCase()
                : this.formData?.energyLabel
                ? this.formData?.energyLabel.toLowerCase()
                : "",
              rules: this.requiredFields ? "required" : "",
              placeholder: "Energy label",
              classes: "col-sm-4 col-12",
              defaultValue: "",
              floatingLabel: false,
              label: `${this.$t("Energy label")}*`,
              options: [
                {
                  text: this.$t("Select energylabel"),
                  value: "",
                },
                {
                  text: "A",
                  value: "a",
                },
                {
                  text: "B",
                  value: "b",
                },
                {
                  text: "C",
                  value: "c",
                },
                {
                  text: "D",
                  value: "d",
                },
                {
                  text: "E",
                  value: "e",
                },
                {
                  text: "F",
                  value: "f",
                },
                {
                  text: "G",
                  value: "g",
                },
                {
                  text: "None",
                  value: "none",
                },
              ],
            },
            {
              mode: "passive",
              name: "outsideSpace",
              component: "MultiselectInput",
              value:
                listing && Array.isArray(listing.outsideSpace)
                  ? listing.outsideSpace
                  : (listing &&
                      listing.outsideSpace.length &&
                      listing.outsideSpace.split(",").map((val) => ({
                        value: val,
                        text: this.$t("outsideSpace")[val.toLowerCase()],
                      }))) ||
                    [],
              rules: this.requiredFields ? "required" : "",
              placeholder: this.$t("Select option"),
              classes: "col-sm-6 col-12",
              multiple: true,
              searchable: false,
              options: [
                {
                  value: "None",
                  text: "Geen",
                },
                {
                  value: "Garden",
                  text: "Tuin",
                },
                {
                  value: "Roofterras",
                  text: "Dakterras",
                },
                {
                  value: "Balcony",
                  text: "Balkon",
                },
              ],
              defaultValue: [],
              label: `${this.addListingLocale.propertyDetails.outsideSpace[0]}*`,
            },
            {
              mode: "passive",
              name: "storage",
              component: "MultiselectInput",
              value:
                listing && Array.isArray(listing.storage)
                  ? listing.storage
                  : (listing &&
                      listing.storage.length &&
                      listing.storage.split(",").map((val) => ({
                        value: val,
                        text: this.$t("storage")[val.toLowerCase()],
                      }))) ||
                    [],
              rules: this.requiredFields ? "required" : "",
              placeholder: this.$t("Select option"),
              classes: "col-sm-6 col-12",
              multiple: true,
              searchable: false,
              options: [
                {
                  value: "none",
                  text: "Geen",
                },
                {
                  value: "bicycle shed",
                  text: "Fietsenstalling",
                },
                {
                  value: "garage",
                  text: "Garage",
                },
                {
                  value: "Storage",
                  text: "Opslagruimte",
                },
              ],
              defaultValue: [],
              label: `${this.addListingLocale.otherinfo.storage[0]}*`,
            },
            {
              mode: "passive",
              name: "parkingFacilities",
              component: "MultiselectInput",
              value:
                listing && Array.isArray(listing.parkingFacilities)
                  ? listing.parkingFacilities
                  : (listing &&
                      listing.parkingFacilities.length &&
                      listing.parkingFacilities.split(",").map((val) => ({
                        value: val,
                        text: this.$t("parking")[val.toLowerCase()],
                      }))) ||
                    [],
              rules: this.requiredFields ? "required" : "",
              placeholder: this.$t("Select option"),
              classes: "col-md-6 col-12",
              searchable: true,
              multiple: true,
              options: [
                {
                  value: "On property",
                  text: " Op eigen terrein",
                },
                {
                  value: "Parking permit",
                  text: "Parkeervergunning",
                },
                {
                  value: "Public parking",
                  text: "Openbaar parkeren",
                },
              ],
              defaultValue: [],
              label: `${this.addListingLocale.otherinfo.parkingFacilities[0]}*`,
            },

            {
              mode: "passive",
              name: "lift",
              component: "RadioInput",
              emitWithName: true,
              value: listing && listing.lift != undefined ? listing.lift : false,
              rules: "required",
              classes: "col-sm-3 col-12",
              options: [
                {
                  value: false,
                  text: this.$t("no"),
                },
                {
                  value: true,
                  text: this.$t("yes"),
                },
              ],
              defaultValue: false,
              label: this.$t("lift"),
            },

            {
              mode: "passive",
              name: "description",
              component: "RichTextEditor",
              value: listing && listing.description ? listing.description : null,
              rules: this.requiredFields ? "required" : "",
              placeholder: this.addListingLocale.descriptionPlaceholder,
              classes: "col-12",

              defaultValue: null,
              label: `${this.$t("description")}*`,
              editorToolbar: this.customToolbar,
            },
          ],
        },
        // {
        //   partialName: "otherinfo",
        //   heading: this.addListingLocale.otherinfo.heading,
        //   inputs: [

        //   ],
        // },
      ];
    },
    formHeading() {
      const { mode } = this.$route.query;
      return mode === "edit"
        ? this.addListingLocale.editHeading
        : this.addListingLocale.addHeading;
    },
    addressInitialValues() {
      // const { mode, listingId } = this.$route.query;
      // if (mode !== "edit" || !listingId) return {};
      console.log("addressInitialValues");
      return {
        zipcode: this.listing && this.listing.zipcode,
        streetName: this.listing && this.listing.streetName,
        houseNr: this.listing && this.listing.houseNr,
        houseAd: this.listing && this.listing.houseAd,
        municipality: this.listing && this.listing.municipality,
        city: this.listing && this.listing.city,
      };
    },
    addListingLocale() {
      return this.$t("broker.addListing");
    },
  },
  methods: {
    ...mapActions("listing", [
      "addListing",
      "getBrokerListing",
      "resetListing",
      "getLandlords",
    ]),
    goToPrevStep() {
      if (this.activeStep > 1) {
        this.activeStep -= 1;
      }
    },
    onMouseEnter(required) {
      this.status = required ? "Available" : "Draft";
      this.submitted = true;
    },
    onMouseLeave() {
      this.submitted = false;
    },
    async onSubmit() {
      if (
        (this.formData.feature_images.length == 0 || this.formData.images.length == 0) &&
        this.activeStep == 2
      ) {
        return;
      }

      this.resetForm = false;
      this.submitted = false;
      // this.formData.description = this.description;
      console.log("formData", this.formData);
      const body = { ...this.formData };
      console.log("body", body);
      body.BrokerId = this.user.id;

      body.price = body.price
        ? ("" + body.price).replaceAll(".", "").replaceAll(",", ".")
        : body.price;
      body.deposit = body.deposit
        ? ("" + body.deposit).replaceAll(".", "").replaceAll(",", ".")
        : body.deposit;

      body.preferredMinIncome = body.preferredMinIncome
        ? (body.preferredMinIncome + "").replaceAll(".", "").replaceAll(",", ".")
        : body.preferredMinIncome;

      body.preferred_max_income = body.preferred_max_income
        ? (body.preferred_max_income + "").replaceAll(".", "").replaceAll(",", ".")
        : body.preferred_max_income;

      body.serviceCost = body.serviceCost
        ? (body.serviceCost + "").replaceAll(".", "").replaceAll(",", ".")
        : body.serviceCost;

      body.utilityCost = body.utilityCost
        ? (body.utilityCost + "").replaceAll(".", "").replaceAll(",", ".")
        : body.utilityCost;

      this.arrFields.forEach((fieldKey) => {
        if (Array.isArray(body[fieldKey])) {
          body[fieldKey] = body[fieldKey].map((field) => field.value);
        }
      });
      body.currentStatus = this.status;
      // body.progress = 100;
      // if (body.currentStatus === "Draft") {
      //   body.progress = this.getProgress();
      // }
      if (this.activeStep === 1 || this.activeStep === 3) {
        const { mode, listingId } = this.$route.params;
        if (mode === "edit" && !isNaN(+listingId) && this.activeStep === 3) {
          await this.addListing({
            body,
            method: "PUT",
            urlDynamicPart: listingId,
          });
        } else {
          if (mode != "edit")
            await this.addListing({
              body,
              mode: this.activeStep === 1 ? "find" : "create",
            });
        }
        if (this.activeStep === 3) {
          this.resetForm = true;
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          });

          return this.$router.push("/broker/listings");
        }
      }

      this.activeStep += 1;

      if (this.$route.query.mode === "edit") return;
      this.$store.commit("listing/setListing", this.formData);
      // this.$router.push({
      //   path: this.$route.path,
      //   query: { ...this.$route.query, activeStep: this.activeStep },
      // });

      // this.makeToast("Listing Added Successfully");
      // this.$router.push("/broker/listings");
    },
    onPartialData(data) {
      console.log("onPartialData", data);
      if (data.partialName == "address" && data.formData.energyLabel) {
        this.disabledEnergyLabel = true;
      }

      for (let key in data.formData) {
        if (key === "preferredMinIncomeRadios" && data.formData[key] === "3x") {
          this.formData.preferredMinIncome = "3x";
          continue;
        }
        if (key in this.formData === false || data.formData[key] == null) continue;
        if (
          (Array.isArray(data.formData[key]) || typeof data.formData[key] === "string") &&
          !data.formData[key].length
        )
          continue;
        let field = data.formData[key];
        // if (!isNaN(parseFloat(field))) {
        //   field = +field;
        // }
        this.$set(this.formData, key, field);
      }
    },
    // getProgress() {
    //   const totalFields = Object.keys(this.formData).length;
    //   let filledFields = 0;
    //   for (let key in this.formData) {
    //     const val = this.formData[key];
    //     if (val == null) continue;
    //     if ((Array.isArray(val) || typeof val === "string") && !val.length)
    //       continue;
    //     filledFields += 1;
    //   }

    //   return +((filledFields / totalFields) * 100).toFixed(2);
    // },
    inputChange(data) {
      this.formData[data.name] = data.value;
      if (data.name === "propertyType") {
        const val = data.value.toLowerCase();
        if (val === "studio" || val === "appartment") {
          return this.toggleInput("hide", "plotArea", "hidePlotAreaField");
        }
        return this.toggleInput("show", "plotArea", "hidePlotAreaField", null);
      }
      if (data.name === "exServiceCost") {
        if (data.value === false)
          return this.toggleInput("hide", "serviceCost", "hideServiceCostField", null);
        return this.toggleInput("show", "serviceCost", "hideServiceCostField");
      }
      if (data.name === "exUtilityCost") {
        if (data.value === false)
          return this.toggleInput("hide", "utilityCost", "hideUtilityCostField", null);
        this.toggleInput("show", "utilityCost", "hideUtilityCostField");
      }
      if (data.name === "preferredMinIncomeRadios") {
        if (data.value === "3x") {
          return this.toggleInput(
            "hide",
            "preferredMinIncome",
            "hidePreferredMinIncomeField",
            null
          );
        }

        this.toggleInput("show", "preferredMinIncome", "hidePreferredMinIncomeField");
      }

      if (data.name === "rental_period_type") {
        if (data.value === "undetermined") {
          return this.toggleInput("hide", "rental_period", "hideRentalPeriod", null);
        }

        this.toggleInput("show", "rental_period", "hideRentalPeriod");
      }

      if (data.name === "is_yearly_increament") {
        return this.toggleInput(
          data.value ? "show" : "hide",
          "rental_period",
          "hideYearlyRentIncreasePercentage",
          null
        );
      }
    },
    toggleInput(op, field, hideKey, value) {
      if (op === "hide") {
        this.$delete(this.formData, field);
        return (this[hideKey] = true);
      }
      this.$set(this.formData, field, value);
      this[hideKey] = false;
    },
  },
  async created() {
    console.log("this.formData", this.formData);
    this.$store.commit("listing/setListing", this.formData);

    const { mode, listingId } = this.$route.params;
    if (mode === "edit" && listingId) {
      this.edit = true;
      this.activeStep = 0;
      await this.getBrokerListing(listingId);

      // this.description = this.listing.description;
      if (this.listing.exServiceCost === false) {
        this.toggleInput("hide", "serviceCost", "hideServiceCostField");
      }
      if (this.listing.exUtilityCost === false) {
        this.toggleInput("hide", "utlityCost", "hideUtilityCostField");
      }
      const propertyType = this.listing.propertyType.toLowerCase();

      if (propertyType === "appartment" || propertyType === "studio") {
        this.toggleInput("hide", "plotArea", "hidePlotAreaField");
      }
      this.formData = this.listing;
      this.formData.images = this.listing.photos
        .filter((x) => !x.featured)
        .map((photo) => {
          if (!photo) {
            return;
          }
          return {
            // the server file reference
            id: photo.id,
            source: photo.name,

            // set type to limbo to tell FilePond this is a temp file
            options: {
              type: "local",
              metadata: {
                id: photo.id,
                listingId: photo.ListingId,
              },
            },
          };
        });
      this.formData.feature_images = [];
      if (this.listing.feature_image) {
        this.formData.feature_images = [
          {
            // the server file reference
            id: this.listing.feature_image.id,
            source: this.listing.feature_image.name,

            // set type to limbo to tell FilePond this is a temp file
            options: {
              type: "local",
              metadata: {
                id: this.listing.feature_image.id,
                listingId: this.listing.feature_image.ListingId,
              },
            },
          },
        ];
      }
      this.activeStep = this.listing.zipcode ? 2 : 1;
    } else {
      this.resetListing();
    }
    this.getLandlords();
  },
};
</script>

<style lang="scss" scoped>
.broker__addlistings {
  padding: 100px 50px;
  max-width: 900px;
  margin: auto;

  @include md {
    padding: 100px 20px;
  }

  @include sm {
    padding: 100px 50px;
  }

  @include customMax(630px) {
    padding: 100px 20px;
  }

  &__submit {
    position: sticky;
    top: 10px;
    height: 250px;
    background: #ffffff;
    border-radius: 5px;
    padding: 18px 50px 40px 50px;

    @include lg {
      padding: 18px 30px 40px 30px;
    }

    @include md {
      padding: 28px 100px 40px 100px;
    }

    @include xs {
      padding: 18px 20px 40px 20px;
    }

    &__heading {
      @include font(1.375rem, black, bold);
    }
  }

  &__inputData {
    margin-bottom: 22px;

    // &__container {
    //   max-width: 550px;
    // }
    &__card {
      &__address {
        // max-width: 550px;
        @include sm {
          max-width: 800px;
        }
      }
    }
  }

  &__address {
    width: 56px;
    height: 56px;
  }

  &__btns {
    button {
      @include xs {
        display: block;
        width: 100%;
      }
    }
  }
}

.errors {
  display: none;
}

.errors:has(.error) {
  display: block;
}
</style>
