<template>
  <div>
    <CardV2 class="mb-4">
      <FormPartial
        @input-with-name="inputChange"
        :partialName="brokerPreferences.partialName"
        :submit="submitted"
        :formFields="brokerPreferences.inputs"
        @partialData="onPartialData"
        :resetForm="resetForm"
      />
    </CardV2>
    <SuggestedViewDates
      class="mb-4"
      :initialDates="initalPreferredDate"
      :heading="addListingLocale.suggestedViewDate.heading"
      :btnText="addListingLocale.suggestedViewDate.btnText"
      partialName="viewDates"
      :submit="submitted"
      @partialData="onPartialData"
    />

    <CardV2 class="mb-4">
      <card-heading :heading="$t('contact_information_landlord')" externalClass="mb-4" />
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <Multiselect
              v-model="formData.landlord_id"
              deselect-label="remove this value"
              label="first_name"
              :placeholder="$t('Select contact')"
              :options="landlords.map((type) => type.id)"
              :searchable="true"
              :allow-empty="true"
              :multiple="false"
              :custom-label="customLabel"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6">
          <ButtonWithIcon
            @click.native="enableContactForm()"
            :text="$t('Add new contact')"
          >
            <Plus />
          </ButtonWithIcon>
        </div>
      </div>
      <FormPartial
        v-if="showContactForm"
        @input-with-name="inputChange"
        :partialName="landlord.partialName"
        :submit="submitted"
        :formFields="landlord.inputs"
        @partialData="onPartialData"
        :resetForm="resetForm"
      />
    </CardV2>
  </div>
</template>
<script>
import SuggestedViewDates from "@/components/Broker/Listings/add/SuggestedViewDates.vue";
import CardHeading from "@/components/general/CardHeading.vue";
import FormPartial from "@/components/ui/form/FormPartial.vue";
import { mapGetters, mapActions } from "vuex";
import toastMixin from "@/mixins/toastMixin";
import CardV2 from "@/components/general/CardV2.vue";
import Multiselect from "vue-multiselect";
import ButtonWithIcon from "../../../components/ui/ButtonWithIcon.vue";
import Plus from "../../../components/svg/Plus.vue";

export default {
  name: "Step3",
  props: ["submitted"],
  mixins: [toastMixin],
  components: {
    CardHeading,
    FormPartial,
    SuggestedViewDates,
    CardV2,
    Multiselect,
    ButtonWithIcon,
    Plus,
  },
  data() {
    return {
      showContactForm: false,
      resetForm: false,
      hidePlotAreaField: false,
      hideServiceCostField: true,
      hideUtilityCostField: true,
      hidePreferredMinIncomeField: false,
      requiredFields: true,
      formData: {
        max_monthly_income_required: false,
        income_type: "gross",
        landlord_id: null,
        streetName: null,
        houseNr: null,
        houseAd: null,
        municipality: null,
        city: null,
        price: null,
        exServiceCost: null,
        exUtilityCost: null,
        serviceCost: null,
        utilityCost: null,
        deposit: null,
        availablityDate: null,
        interior: null,
        propertyType: null,
        totalRooms: null,
        totalBedrooms: null,
        livingArea: null,
        plotArea: null,
        volumeInCBM: null,
        yearOfConstruction: null,
        outsideSpace: [],
        energyLabel: null,
        parkingFacilities: [],
        storage: [],
        preferredHousehold: [],
        preferredAnimals: null,
        preferredMinIncome: null,
        photos: [],
        floorplan: [],
        description: null,
        zipcode: null,
        preferredViewingDates: [],
      },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("listing", ["listing", "landlords"]),
    initalPreferredDate() {
      return this.listing && this.listing.preferredViewingDates
        ? this.listing.preferredViewingDates
        : [];
    },
    brokerPreferences() {
      const listing = this.listing;
      let preferredHousehold =
        listing && listing.preferredHousehold ? listing.preferredHousehold : [];
      return {
        partialName: "preferences",
        heading: this.addListingLocale.preferences.heading,
        inputs: [
          {
            mode: "passive",
            name: "preferredHousehold",
            component: "MultiselectInput",
            value: Array.isArray(preferredHousehold)
              ? preferredHousehold
              : (preferredHousehold &&
                  preferredHousehold.length &&
                  preferredHousehold.split(",").map((val) => ({
                    value: val,
                    text: this.$t("household")[val.toLowerCase()],
                  }))) ||
                [],
            rules: this.requiredFields ? "required" : "",
            placeholder: this.$t("Select option"),
            classes: "col-sm-6 col-12",
            multiple: true,
            searchable: false,
            options: [
              {
                value: "single",
                text: "Alleenstaand",
              },
              {
                value: "couple",
                text: "Koppel",
              },
              {
                value: "family",
                text: "Gezin",
              },
              {
                text: "Groepsbewoning",
                value: "group occupancy",
              },
            ],
            defaultValue: [],

            label: this.addListingLocale.preferences.household,
          },
          {
            mode: "passive",
            name: "preferredAnimals",
            component: "SelectInput",
            value:
              this.listing && this.listing.preferredAnimals != null
                ? this.listing.preferredAnimals
                : "",
            rules: this.requiredFields ? "required" : "",
            placeholder: this.$t("Select option"),
            classes: "col-sm-6 col-12",
            options: [
              {
                value: "",
                text: this.$t("Select option"),
                disabled: true,
                hidden: true,
              },
              {
                value: true,
                text: this.$t("Allowed"),
              },
              {
                value: false,
                text: this.$t("Not Allowed"),
              },
            ],
            defaultValue: "",
            label: "Animals",
          },
          {
            mode: "passive",
            name: "income_type",
            component: "RadioInput",
            rules: "required",
            classes: "col-6",
            title: "Set type of monthly income requirement",
            textInputRadios: ["input"],
            options: [
              {
                text: this.$t("Gross Income"),
                value: "gross",
              },
              {
                text: this.$t("Net Income"),
                value: "net",
              },
            ],
            value: this.listing?.income_type ? this.listing.income_type : "gross",
          },
          {
            mode: "passive",
            name: "preferredMinIncomeRadios",
            component: "RadioInput",
            rules: this.requiredFields ? "required" : "",
            classes: "col-6",
            title: "Minimal monthly income requirement",
            textInputRadios: ["input"],
            options: [
              {
                text: this.$t("3x monthly rent"),
                value: "3x",
              },
              {
                text: this.$t("Custom requirement"),
                value: "custom",
              },
            ],
            value:
              !this.listing || (this.listing && this.listing.rent3x) ? "3x" : "custom",
          },
          {
            mode: "passive",
            name: "max_monthly_income_required",
            component: "RadioInput",
            rules: "required",
            classes: "col-6",
            title: "Maximum monthly income requirement",
            textInputRadios: ["input"],
            options: [
              {
                text: this.$t("No"),
                value: false,
              },
              {
                text: this.$t("Yes"),
                value: true,
              },
            ],
            value: this.listing ? this.listing.max_monthly_income_required == 1 : false,
          },
          {
            mode: "passive",
            name: "preferredMinIncome",
            hide: this.hidePreferredMinIncomeField,
            component: "TextInput",
            value:
              this.listing && this.listing.preferredMinIncome
                ? this.listing.preferredMinIncome.toString()
                : "",
            rules: this.requiredFields ? "required" : "",
            placeholder: this.$t("set custom requirement"),
            classes: "col-sm-6 col-12",
            masked: true,
            mask: [
              {
                mask: "num",
                blocks: {
                  num: {
                    mask: Number, // enable number mask
                    scale: 2,
                    signed: false,
                    thousandsSeparator: ".",
                    padFractionalZeros: false,
                    normalizeZeros: true,
                    radix: ",",

                    // additional number interval options (e.g.)
                    min: 0,
                    max: 99999999,
                  },
                },
              },
            ],
            floatingLabel: false,
            prepend: "€",
            defaultValue: "",
          },
          {
            mode: "passive",
            name: "preferred_max_income",
            hide: !this.formData.max_monthly_income_required,
            component: "TextInput",
            value:
              this.listing && this.listing.preferred_max_income
                ? this.listing.preferred_max_income.toString()
                : "",
            rules: this.requiredFields ? "required" : "",
            placeholder: this.$t("set custom requirement"),
            classes: "col-sm-6 col-12",
            masked: true,
            mask: [
              {
                mask: "num",
                blocks: {
                  num: {
                    mask: Number, // enable number mask
                    scale: 2,
                    signed: false,
                    thousandsSeparator: ".",
                    padFractionalZeros: false,
                    normalizeZeros: true,
                    radix: ",",

                    // additional number interval options (e.g.)
                    min: 0,
                    max: 99999999,
                  },
                },
              },
            ],
            floatingLabel: false,
            prepend: "€",
            defaultValue: "",
          },
        ],
      };
    },
    showForm() {
      const { mode } = this.$route.query;

      if (mode !== "edit") return true;
      return this.listing ? true : false;
    },
    formHeading() {
      const { mode } = this.$route.query;
      return mode === "edit"
        ? this.addListingLocale.editHeading
        : this.addListingLocale.addHeading;
    },
    addListingLocale() {
      return this.$t("broker.addListing");
    },
    landlord() {
      return {
        partialName: "landlord",
        inputs: [
          {
            name: "first_name",
            component: "TextInput",
            // value: this.listing?.landlord?.first_name ?? '',
            placeholder: this.$t("firstname"),
            label: this.$t("firstname"),
            classes: "col-sm-6 col-12 pb-0",
            floatingLabel: false,
            datalist: this.landlords.map((l) => ({
              id: l.id,
              value: `${l.first_name} ${l.last_name}`,
            })),
          },
          {
            name: "last_name",
            component: "TextInput",
            // value: this.listing?.landlord?.last_name ?? '',
            placeholder: this.$t("lastname"),
            label: this.$t("lastname"),
            classes: "col-sm-6 col-12 pb-0",
            floatingLabel: false,
          },
          {
            name: "email",
            component: "TextInput",
            // value: this.listing?.landlord?.email ?? '',
            placeholder: this.$t("email_address"),
            label: this.$t("email_address"),
            classes: "col-12 pt-0",
            floatingLabel: false,
          },
        ],
      };
    },
  },
  mounted() {
    this.formData.landlord_id = this.listing?.landlord_id;
  },
  methods: {
    ...mapActions("listing", ["addListing", "setListing", "resetListing"]),
    onPartialData(data) {
      if (data.partialName == "landlord") {
        let landlord = data.formData;
        this.$emit("partialData", {
          partialName: data.partialName,
          formData: { landlord },
        });
      } else {
        this.$emit("partialData", data);
      }
      this.$emit("partialData", {
        partialName: "landlord_id",
        formData: { landlord_id: this.formData.landlord_id },
      });
    },
    inputChange(data) {
      console.log("data", data);
      this.formData[data.name] = data.value;
      if (data.name === "propertyType") {
        const val = data.value.toLowerCase();
        if (val === "studio" || val === "appartment") {
          return this.toggleInput("hide", "plotArea", "hidePlotAreaField");
        }
        return this.toggleInput("show", "plotArea", "hidePlotAreaField", null);
      }
      if (data.name === "exServiceCost") {
        if (data.value === false)
          return this.toggleInput("show", "serviceCost", "hideServiceCostField", null);
        return this.toggleInput("hide", "serviceCost", "hideServiceCostField");
      }
      if (data.name === "exUtilityCost") {
        if (data.value === false)
          return this.toggleInput("show", "utilityCost", "hideUtilityCostField", null);
        this.toggleInput("hide", "utilityCost", "hideUtilityCostField");
      }
      if (data.name === "preferredMinIncomeRadios") {
        if (data.value === "3x") {
          return this.toggleInput(
            "hide",
            "preferredMinIncome",
            "hidePreferredMinIncomeField",
            null
          );
        }

        this.toggleInput("show", "preferredMinIncome", "hidePreferredMinIncomeField");
      }
    },
    toggleInput(op, field, hideKey, value) {
      if (op === "hide") {
        this.$delete(this.formData, field);
        return (this[hideKey] = true);
      }
      this.$set(this.formData, field, value);
      this[hideKey] = false;
    },

    enableContactForm() {
      this.showContactForm = true;
      this.formData.landlord_id = null;
    },
    customLabel(option) {
      let landlord = this.landlords.find((x) => x.id == option);
      if (landlord) {
        return `${landlord.first_name} ${landlord.last_name}`;
      }

      return "";
    },
  },
  watch: {
    listing(newListing, oldLsiting) {
      console.log("watch listing", newListing, oldLsiting);
    },
  },
};
</script>
