<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_726_8)">
      <path
        d="M19.7281 17.2914L15.8332 13.3973C15.6574 13.2215 15.4191 13.1238 15.1691 13.1238H14.5324C15.6105 11.7449 16.2512 10.0109 16.2512 8.12422C16.2512 3.63633 12.6141 0 8.12578 0C3.6375 0 0 3.63633 0 8.12422C0 12.6121 3.63711 16.2484 8.12578 16.2484C10.0125 16.2484 11.7473 15.6078 13.1262 14.5297V15.1664C13.1262 15.4164 13.2238 15.6547 13.3996 15.8305L17.2945 19.7246C17.6617 20.0918 18.2555 20.0918 18.6188 19.7246L19.7242 18.6191C20.0914 18.252 20.0914 17.6586 19.7281 17.2914ZM8.12578 13.7484C5.01875 13.7484 2.50078 11.2352 2.50078 8.12422C2.50078 5.01758 5.01445 2.5 8.12578 2.5C11.2328 2.5 13.7508 5.01328 13.7508 8.12422C13.7508 11.2309 11.2371 13.7484 8.12578 13.7484ZM8.12656 4.375C6.53359 4.375 5.24219 5.66602 5.24219 7.25898C5.24219 8.54648 7.12734 10.8938 7.84961 11.7457C7.88348 11.7862 7.92585 11.8188 7.9737 11.8412C8.02155 11.8636 8.07374 11.8752 8.12656 11.8752C8.17939 11.8752 8.23157 11.8636 8.27942 11.8412C8.32728 11.8188 8.36964 11.7862 8.40352 11.7457C9.12578 10.8938 11.0109 8.54688 11.0109 7.25898C11.0109 5.66602 9.71953 4.375 8.12656 4.375ZM8.12656 8.125C7.60859 8.125 7.18906 7.70508 7.18906 7.1875C7.18906 6.66953 7.60898 6.25 8.12656 6.25C8.64414 6.25 9.06406 6.66953 9.06406 7.1875C9.06406 7.70508 8.64414 8.125 8.12656 8.125Z"
        :fill="fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_726_8">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props:{
    fill:{
      type:String,
      default:'#0A9250'
    }
  }
}
</script>