<template>
  <div class="broker__addlistings__inputData">
    <CardV2 cardClass="broker__addlistings__inputData__card">
      <card-heading :heading="heading" externalClass="mb-4" />

        <div class="row">
          <BCol cols="6">
            <div
              class="d-flex mb-3 align-items-center"
              v-for="({ ...date }, i) in dates"
              :key="i"
            >
              <DatePicker class="flex-1" v-bind="date" v-model="datesData[i]" />
              <Times
                class="ml-4 cursor-pointer"
                @click.native="deleteDate(i)"
              />
            </div>
          </BCol>
        </div>
        <ButtonWithIcon
          @click.native="addDate"
          :text="btnText"
        >
          <Plus />
        </ButtonWithIcon>
  
    </CardV2>
  </div>
</template>

<script>
import CardV2 from "@/components/general/CardV2.vue";
import CardHeading from "@/components/general/CardHeading.vue";

import Plus from "../../../svg/Plus.vue";
import ButtonWithIcon from "../../../ui/ButtonWithIcon.vue";
import DatePicker from "../../../ui/form/controls/Datepicker.vue";
import Times from "../../../svg/Times.vue";
export default {
  props: ["heading", "btnText", "submit", "initialDates"],
  components: {
    CardV2,
    CardHeading,
    Plus,
    ButtonWithIcon,
    DatePicker,
    Times,
  },
  data() {
    return {
      datesData: [],
      dates: [],
    };
  },
  methods: {
    addDate(initialValue = "") {
      const value = typeof initialValue === "string" ? initialValue : "";
      this.dates.push({
        name: `date${this.dates.length}`,
        value: "",
        rules: "",
        placeholder: this.$t("date"),
        disablePastDates: true,
        disableWeekends: true,
      });
      this.datesData.push(value);
    },
    deleteDate(i) {
      this.datesData.splice(i, 1);
      this.dates.splice(i, 1);
    },
  },
  watch: {
    submit(newVal) {
      if (newVal) {
        this.$emit("partialData", {
          formData: { preferredViewingDates: this.datesData },
          partialName: this.partialName,
        });
      }
    },
  },
  created() {
    this.initialDates.forEach((date) => {
      this.addDate(date);
    });
  },
};
</script>

<style lang="scss" scoped>
.broker__addlistings {
  &__media {
    margin-bottom: 22px;
    &__container {
      max-width: 550px;
      margin-right: 10px;
    }
    &__filenamesContainer {
      background: $gray2;
      &__text {
        @include font(1rem, #504c4c, 400);
      }
    }
    &__featured {
      transform: translateY(7px);
    }
  }
}
</style>
